import { colors } from 'provides/theme/const';

import { ButtonColors as IButtonColors } from './type';

const { mainBrownColor, mainBlueColor, lightBlueColor, primaryTextColor } =
  colors;

export const ButtonColors: IButtonColors = {
  brown: mainBrownColor,
  blue: mainBlueColor,
  blueLight: lightBlueColor,
  primaryText: primaryTextColor,
};
