import { ComponentProps } from 'react';

import LibraryButton from '@mui/material/Button';

type LibraryButtonProps = ComponentProps<typeof LibraryButton>;

export interface ButtonColors {
  brown: string;
  blue: string;
  blueLight: string;
  primaryText: string;
}

export interface ButtonProps extends LibraryButtonProps {
  actionVariant?: ButtonVariant;
  isActive?: boolean;
  popupId?: string;

  icon?: JSX.Element;

  redirectUrl?: string;
}

export enum ButtonVariant {
  default = 'default',
  download = 'download',
  save = 'save',
  info = 'info',
}

export enum ButtonType {
  submit = 'submit',
  button = 'button',
}
