import * as React from 'react';

const Icon: React.FC = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.85386 6.9993H8.1782H8.26562H8.61857H9.26562H9.97148H11.2656C11.4094 6.9993 11.5496 7.01446 11.6847 7.04327C12.5881 7.23588 13.2656 8.03846 13.2656 8.9993V14.9993C14.3702 14.9993 15.2656 15.8947 15.2656 16.9993C15.2656 18.1038 14.3702 18.9993 13.2656 18.9993V18.9993H12.2656H10.2656C9.16106 18.9993 8.26562 18.1039 8.26562 16.9993V9.17577V8.17577H7.26562H6.85386C6.52899 8.17577 6.26562 7.91241 6.26562 7.58753C6.26562 7.26266 6.52899 6.9993 6.85386 6.9993ZM12.2656 17.9993V17.9993V16.9993V15.9993V14.9993V8.9993C12.2656 8.44701 11.8179 7.9993 11.2656 7.9993H10.2656H9.26562V8.17577V8.9993V16.9993C9.26562 17.5516 9.71334 17.9993 10.2656 17.9993H11.2656H12.2656ZM13.2656 17.9993C13.8179 17.9993 14.2656 17.5516 14.2656 16.9993C14.2656 16.447 13.8179 15.9993 13.2656 15.9993V17.9993ZM8.61857 6.09852C8.56598 6.06719 8.51457 6.03409 8.46442 5.9993C7.77552 5.52138 7.32442 4.72479 7.32442 3.82284C7.32442 2.36091 8.50955 1.17578 9.97148 1.17578C11.4334 1.17578 12.6185 2.36091 12.6185 3.82284C12.6185 4.72906 12.1631 5.52892 11.4687 6.00606C11.4031 6.05116 11.3353 6.09337 11.2656 6.13252C10.883 6.34737 10.4415 6.4699 9.97148 6.4699C9.72704 6.4699 9.49034 6.43677 9.26562 6.37475C9.0359 6.31135 8.8187 6.21776 8.61857 6.09852ZM11.6185 3.82284C11.6185 4.73249 10.8811 5.4699 9.97148 5.4699C9.06183 5.4699 8.32442 4.73249 8.32442 3.82284C8.32442 2.91319 9.06183 2.17578 9.97148 2.17578C10.8811 2.17578 11.6185 2.91319 11.6185 3.82284Z"
        fill="#003251"
      />
    </svg>
  );
};

export default Icon;
