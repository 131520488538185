import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { setOrganisationInfo } from 'reducers/organisationSlice';

import {
  createToastError,
  createToastSuccess,
} from 'components/alert-info/controls';

import { lexics } from 'data/lexics';

import { getHumanDate } from 'utils/helper';

import { BackendError } from 'types/api';
import { ListItem } from 'types/common';
import {
  LearningStatisticsType,
  VisitDetailingStatisticsType,
} from 'types/statistics';
import { User } from 'types/user';

import { getCompanyQuery } from './helper';
import {
  CreateClientOrganisationRequest,
  CreateClientOrganisationResponse,
  CreateClientRequest,
  CreateClientResponse,
  CreateExcelClientOrganisationsRequest,
  CreateExcelClientOrganisationsResponse,
  GetCitiesRequest,
  GetCitiesResponse,
  GetClientOrganisationRequest,
  GetClientOrganisationResponse,
  GetClientOrganisationsListRequest,
  GetClientOrganisationsListResponse,
  GetClientOrganisationsRequest,
  GetClientOrganisationsResponse,
  GetClientOrganisationTypeAllRequest,
  GetClientOrganisationTypeAllResponse,
  GetClientRequest,
  GetClientResponse,
  GetClientsRequest,
  GetClientsResponse,
  GetCompanyParams,
  GetCompanyResponse,
  GetDepartmentsRequest,
  GetDepartmentsResponse,
  GetDetailingStatisticsProps,
  GetDetailingStatisticsResponse,
  GetLearningStatisticsProps,
  GetLearningStatisticsResponse,
  GetPagesProps,
  GetPagesResponse,
  GetTagsRequest,
  GetTagsResponse,
  UpdateClientOrganisationRequest,
  UpdateClientOrganisationResponse,
  UpdateClientRequest,
  UpdateClientResponse,
  UpdateManyClientOrganisationsRequest,
  UpdateManyClientOrganisationsResponse,
  UpdateManyClientsRequest,
  UpdateManyClientsResponse,
} from './type';
import { redirectToErrorPage } from './utils';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_API_URL,
  credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  const error = result.error as BackendError;

  if (error) {
    redirectToErrorPage(error);
  }

  return result;
};
const api = createApi({
  tagTypes: [
    'User',
    'Company',
    'CompanyUsers',
    'CompanyUser',
    'ClientOrganisations',
    'Clients',
    'DetailingStats',
    'DetailingStatistics',
    'DetailingStatisticsPages',
    'LearningStats',
    'LearningStatistics',
    'DetailingClientsPages',
    'Tags',
  ],
  keepUnusedDataFor: 86_400,
  refetchOnReconnect: true,
  reducerPath: 'mainApi',
  baseQuery: baseQueryWithReauth,
  endpoints: ({ query, mutation }) => ({
    getUser: query<User, void>({
      query: () => 'user',

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const {
            organisation: { id: organisationId },
          } = data;

          dispatch(
            setOrganisationInfo({
              id: organisationId,
            }),
          );
        } catch {}
      },

      providesTags: ['User'],
    }),

    getCompany: query<GetCompanyResponse, GetCompanyParams>({
      query: ({ id }) => getCompanyQuery(id),

      providesTags: () => ['Company'],
    }),

    getDepartments: query<GetDepartmentsResponse, GetDepartmentsRequest>({
      query: () => 'departments/all',
    }),

    getCities: query<GetCitiesResponse, GetCitiesRequest>({
      query: () => 'cities/all',
    }),

    getClientOrganisationsList: query<
      GetClientOrganisationsListResponse,
      GetClientOrganisationsListRequest
    >({
      query: (params) => {
        return {
          url: 'client-organisations/dropdown/all',
          params,
        };
      },
      transformResponse: (
        organisations: (ListItem & {
          department: string;
          noclients: boolean;
        })[],
      ) => {
        const options: GetClientOrganisationsListResponse = organisations
          .filter(({ noclients }) => !noclients)
          .map(({ id, name, department }) => ({
            label: name,
            value: id,
            department,
          }));

        return options;
      },
    }),

    getDetailingStatistics: query<
      GetDetailingStatisticsResponse,
      GetDetailingStatisticsProps
    >({
      query: ({ id, page, pageAmount }) => {
        return {
          url: `/detailing-stats/admin/${id}/statistics`,
          params: { page, pageAmount },
        };
      },
      providesTags: (_, __, { id, page, pageAmount }) => [
        {
          type: 'DetailingStatistics',
          id,
          page,
          pageAmount,
        },
      ],
    }),

    getDetailingStatisticsPages: query<GetPagesResponse, GetPagesProps>({
      query: ({ id, pageAmount }) => {
        return {
          url: `detailing-stats/admin/${id}/statistics/pages`,
          params: { pageAmount },
        };
      },
      providesTags: (_, __, { id }) => [
        {
          type: 'DetailingStatisticsPages',
          id,
        },
      ],
    }),

    getDetailingStatisticsById: query<
      VisitDetailingStatisticsType,
      string | string[] | undefined
    >({
      query: (id) => `detailing-stats/admin/user-results/${id}`,

      transformResponse: (data: VisitDetailingStatisticsType) => {
        return {
          ...data,
          visitDate: getHumanDate(data.visitDate),
        };
      },

      providesTags: (_, __, arg) => [
        { type: 'DetailingStats', id: String(arg) },
      ],
    }),

    getLearningStatistics: query<
      GetLearningStatisticsResponse,
      GetLearningStatisticsProps
    >({
      query: ({
        page,
        take,
        userId,
        testType,
        testId,
        resultOrder,
        datesInterval,
      }) => {
        return {
          url: `/learning-stats/admin/all`,
          params: {
            page,
            take,
            userId,
            testType,
            testId,
            resultOrder,
            datesInterval,
          },
        };
      },
      providesTags: (_, __, { page, take }) => [
        {
          type: 'LearningStatistics',
          page,
          take,
        },
      ],
    }),

    getLearningStatisticsById: query<
      LearningStatisticsType,
      string | string[] | undefined
    >({
      query: (id) => `learning-stats/admin/${id}`,

      transformResponse: (data: LearningStatisticsType) => {
        return data;
      },

      providesTags: (_, __, arg) => [
        { type: 'LearningStats', id: String(arg) },
      ],
    }),

    getClientOrganisationTypeAll: query<
      GetClientOrganisationTypeAllResponse,
      GetClientOrganisationTypeAllRequest
    >({
      query: () => {
        return {
          url: 'client-organisation-type/all',
        };
      },
    }),

    getClientOrganisations: query<
      GetClientOrganisationsResponse,
      GetClientOrganisationsRequest
    >({
      query: (params) => {
        return {
          url: 'client-organisations/admin/get-all',
          params,
        };
      },
      providesTags: (_, __, { page, pageAmount }) => [
        {
          type: 'ClientOrganisations',
          page,
          pageAmount,
        },
      ],
    }),

    getClientOrganisationsPages: query<
      GetPagesResponse,
      Omit<GetPagesProps, 'id'>
    >({
      query: ({ pageAmount }) => {
        return {
          url: '/client-organisations/admin/get-all/pages',
          params: { pageAmount },
        };
      },
      providesTags: ['DetailingStatisticsPages'],
    }),

    getClientOrganisation: query<
      GetClientOrganisationResponse,
      GetClientOrganisationRequest
    >({
      query: (id) => `client-organisations/admin/${id}`,
    }),

    createClientOrganisation: mutation<
      CreateClientOrganisationResponse,
      CreateClientOrganisationRequest
    >({
      query: (body) => {
        return {
          url: 'client-organisations/admin/create',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(
              lexics.api.response.clientOrganisation.create.success,
            );
          }
        } catch {
          createToastError(lexics.api.response.clientOrganisation.create.error);
        }
      },
      invalidatesTags: ['ClientOrganisations'],
    }),

    updateClientOrganisation: mutation<
      UpdateClientOrganisationResponse,
      UpdateClientOrganisationRequest
    >({
      query: (request) => {
        const { id, ...body } = request;

        return {
          url: `client-organisations/admin/update-one/${id}`,
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(
              lexics.api.response.clientOrganisation.update.success,
            );
          }
        } catch {
          createToastError(lexics.api.response.clientOrganisation.update.error);
        }
      },
      invalidatesTags: ['Clients'],
    }),

    updateManyClientOrganisations: mutation<
      UpdateManyClientOrganisationsResponse,
      UpdateManyClientOrganisationsRequest
    >({
      query: (body) => {
        return {
          url: 'client-organisations/admin/update-many',
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(
              lexics.api.response.clientOrganisation.multi_update.success,
            );
          }
        } catch {
          createToastError(
            lexics.api.response.clientOrganisation.multi_update.error,
          );
        }
      },
      invalidatesTags: ['Clients', 'ClientOrganisations'],
    }),

    createExcelClientOrganisations: mutation<
      CreateExcelClientOrganisationsResponse,
      CreateExcelClientOrganisationsRequest
    >({
      query: ({ body }) => {
        return {
          url: 'client-organisations/admin/create/excel',
          method: 'POST',
          body,
        };
      },
    }),

    getClientsPages: query<GetPagesResponse, Omit<GetPagesProps, 'id'>>({
      query: ({ pageAmount }) => {
        return {
          url: 'clients/admin/get-all/pages',
          params: { pageAmount },
        };
      },
      providesTags: ['DetailingClientsPages'],
    }),

    getClients: query<GetClientsResponse, GetClientsRequest>({
      query: (params) => {
        return {
          url: 'clients/admin/get-all',
          params,
        };
      },
      providesTags: (_, __, { page, pageAmount }) => [
        {
          type: 'Clients',
          page,
          pageAmount,
        },
      ],
    }),

    getClient: query<GetClientResponse, GetClientRequest>({
      query: (id) => `clients/admin/${id}`,
    }),

    updateManyClients: mutation<
      UpdateManyClientsResponse,
      UpdateManyClientsRequest
    >({
      query: (body) => {
        return {
          url: 'clients/admin/update-many',
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(lexics.api.response.client.multi_update.success);
          }
        } catch {
          createToastError(lexics.api.response.client.multi_update.error);
        }
      },
      invalidatesTags: ['Clients'],
    }),

    createClient: mutation<CreateClientResponse, CreateClientRequest>({
      query: (body) => {
        return {
          url: 'clients/admin/create',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(lexics.api.response.client.create.success);
          }
        } catch {
          createToastError(lexics.api.response.client.create.error);
        }
      },
      invalidatesTags: ['Clients'],
    }),

    updateClient: mutation<UpdateClientResponse, UpdateClientRequest>({
      query: (request) => {
        const { id, ...body } = request;

        return {
          url: `clients/admin/update-one/${id}`,
          method: 'PATCH',
          body,
        };
      },

      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(lexics.api.response.client.update.success);
          }
        } catch {
          createToastError(lexics.api.response.client.update.error);
        }
      },
      invalidatesTags: ['Clients'],
    }),

    getTags: query<GetTagsResponse, GetTagsRequest>({
      query: ({ departmentId }) => {
        return {
          url: `tag/${departmentId}`,
        };
      },

      providesTags: (_, __, arg) => [
        {
          type: 'Tags',
          organisationId: arg.departmentId,
        },
      ],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetCompanyQuery,

  useGetDetailingStatisticsQuery,
  useGetDetailingStatisticsPagesQuery,

  useGetDetailingStatisticsByIdQuery,

  useGetLearningStatisticsQuery,
  useGetLearningStatisticsByIdQuery,

  useGetClientOrganisationTypeAllQuery,

  useGetClientOrganisationsQuery,
  useGetClientOrganisationsPagesQuery,

  useGetClientOrganisationQuery,
  useCreateClientOrganisationMutation,
  useUpdateClientOrganisationMutation,
  useUpdateManyClientOrganisationsMutation,
  useCreateExcelClientOrganisationsMutation,

  useGetDepartmentsQuery,
  useGetCitiesQuery,
  useLazyGetClientOrganisationsListQuery,

  useGetClientsPagesQuery,
  useGetClientsQuery,
  useGetClientQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useUpdateManyClientsMutation,

  useLazyGetTagsQuery,
  useGetTagsQuery,
} = api;

export default api;
