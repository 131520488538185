import BackIcon from './back';
import CloseIcon from './close';
import DownloadIcon from './download';
import InfoIcon from './info';
import LoaderIcon from './loader';
import MiniPlusIcon from './mini-plus';
import PlusIcon from './plus';
import SaveIcon from './save';
import SegmentsIcon from './segments';
import SelectDropdownIcon from './select-dropdown';

export default {
  DownloadIcon,
  LoaderIcon,
  SegmentsIcon,
  BackIcon,
  SaveIcon,
  PlusIcon,
  SelectDropdownIcon,
  MiniPlusIcon,
  CloseIcon,
  InfoIcon,
};
